import { useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom"

import { System } from "detect-collisions"
import { createStaticCollisionObjects } from "./districtCollisions.js"

import { ExerciseLoader } from "./ExerciseLoader.js"
import { useRandomHeroes } from "./RandomHeroes.js"
import { hero } from "./assets.js"
import { FloatingLink } from "./floatingLink.js"
import { Hero } from "./heroes.js"
import { gettext } from "./i18n.js"
import { Pannable } from "./pannable.js"
import { generatePath, routes } from "./routes.js"
import { intersect, useInitialData } from "./utils.js"
import { useWorldState } from "./world.js"

export function DistrictRouter() {
  const { districtsBySlug, user } = useWorldState()
  const { urls } = useInitialData("settings")

  const params = useParams()
  const district = districtsBySlug[params.slug]
  const districtSlug = district?.slug

  useEffect(() => {
    if (!districtSlug) return

    const fd = new FormData()
    fd.append("user", user.id)
    fd.append("district", districtSlug)
    navigator.sendBeacon(urls.userInDistrict, fd)
  }, [districtSlug, user.id, urls])

  if (!district) return <Navigate replace to={generatePath(routes.city)} />

  const exercises = district.exercises || []

  return (
    <>
      <Pannable
        identifier={`district-${district.district}`}
        image={district.backgroundImageUrl}
      >
        {(props) => (
          <DistrictContent
            district={district}
            exercises={exercises}
            user={user}
            {...props}
          />
        )}
      </Pannable>

      <FloatingLink
        name={gettext("< City")}
        url={generatePath(routes.city)}
        position={{ x: 0, y: 0 }}
        modifierClass="is-back-to-city"
      />

      <Routes>
        {exercises.map((exercise) => {
          const path = generatePath(routes.exercise, {
            slug: district.slug,
            exercise: exercise.id,
          })
          return (
            <Route
              key={exercise.id}
              path={path}
              element={
                <ExerciseLoader district={district} exercise={exercise} />
              }
            />
          )
        })}
        <Route
          exact
          path={""}
          element={<MaybeRedirect district={district} />}
        />
        <Route
          default
          element={
            <Navigate replace to={generatePath(routes.district, district)} />
          }
        />
      </Routes>
    </>
  )
}

function MaybeRedirect({ district }) {
  if (district.district === "swissmoneyweek") {
    return <Navigate replace to={generatePath(routes.city)} />
  }
  return null
}

function heroAssetGroup(user, district) {
  console.debug({ user, district })
  const sex = user.sex
  const variant = district.district === "challenge" ? "perspective" : "iso"
  return hero[sex][variant]
}

function DistrictContent({ position, setPosition, district, exercises, user }) {
  const systemRef = useRef()
  const heroRef = useRef()

  if (!systemRef.current) {
    systemRef.current = new System()
    createStaticCollisionObjects(systemRef.current, district)
    heroRef.current = addHeroPolygon(
      systemRef.current,
      1000 * position.x,
      1000 * position.y,
    )
  }

  const { classmates } = useWorldState()
  const [randomHeroes, setRandomHeroes] = useRandomHeroes(
    classmates,
    () => addHeroPolygon(systemRef.current, 500, 500),
    district,
  )

  const { x, y } = position
  heroRef.current.x = x * 1000
  heroRef.current.y = y * 1000

  /*
  randomHeroes.forEach((hero) => {
    hero.polygon.x = hero.x * 1000
    hero.polygon.y = hero.y * 1000
  })
  */

  const { DEBUG } = useInitialData("settings")

  // biome-ignore lint/correctness/useExhaustiveDependencies: x and y are required so that recalculations happen when they should.
  useEffect(() => {
    systemRef.current.update()

    negateOverlap(systemRef.current, heroRef.current, ({ x, y }) =>
      setPosition((position) => ({
        ...position,
        x: x / 1000,
        y: y / 1000,
      })),
    )

    systemRef.current.update()

    setRandomHeroes((randomHeroes) =>
      randomHeroes.map((hero) => {
        negateOverlap(systemRef.current, hero.polygon, ({ x, y }) => {
          hero = { ...hero, x: x / 1000, y: y / 1000 }
        })
        return hero
      }),
    )

    if (DEBUG) drawCollisionSystem(systemRef.current)
  }, [x, y, setPosition, setRandomHeroes, DEBUG])

  return (
    <>
      <Helmet>
        <title>{district.name} - FinanceMission World</title>
      </Helmet>
      <canvas /> {/* The collision debug system requires this (currently) */}
      {randomHeroes.map((hero, idx) => (
        <Hero
          key={idx}
          className="hero is-in-district is-other"
          assetGroup={hero.assetGroup}
          items={hero.items}
          style={{
            left: `${hero.x * 100}%`,
            top: `${hero.y * 100}%`,
          }}
          heroName={hero.heroName}
        />
      ))}
      {
        /* Might be not set yet if onboarding is deferred */ user.sex ? (
          <Hero
            assetGroup={heroAssetGroup(user, district)}
            className={`hero is-in-district is-self ${
              Math.random() < 0.5 ? "" : "is-inverted"
            }`}
            items={user.equippedItems}
            style={{
              left: `${position.x * 100}%`,
              top: `${position.y * 100}%`,
            }}
          />
        ) : null
      }
      {exercises.map((exercise) => {
        const props = {
          name: exercise.name,
          description: exercise.description,
          url: generatePath(routes.exercise, {
            slug: district.slug,
            exercise: exercise.id,
          }),
          position: exercise.position,
          stateClass: [
            exercise.isCompleted ? "is-completed" : "is-unlocked",
            exercise.isSuccess ? "is-success" : "",
            exercise.isCurrentBlock ? "is-current-block" : "is-past-blocks",
            exercise.isRequired ? "is-required" : "is-optional",
          ].join(" "),
          method: exercise.method,
        }

        switch (exercise.method) {
          case "resolution":
            return (
              <ResolutionLink
                district={district}
                user={user}
                {...props}
                key={exercise.id}
              />
            )
          default:
            return <ExerciseLink {...props} key={exercise.id} />
        }
      })}
    </>
  )
}

function ExerciseLink(props) {
  const {
    name,
    description,
    url,
    position,
    stateClass,
    modifierClass,
    method,
  } = props
  return (
    <Link
      to={url}
      className={`floating-link is-exercise ${stateClass || ""} ${
        modifierClass || ""
      } is-${method}`}
      style={{ left: `${position.x}%`, top: `${position.y}%` }}
    >
      <div className="floating-link__description">
        <h3>{name}</h3>
        <small>{description}</small>
      </div>
    </Link>
  )
}

function ResolutionLink({ district, user, ...props }) {
  const awarded = intersect(district.resolutionPanels, user.resolutionPanels)
  const completion =
    Math.floor((100 * awarded.length) / district.resolutionPanels.length) || 0
  props.stateStyle = `${completion}%`
  props.stateClass =
    completion >= 100
      ? "is-completed is-success is-resolution"
      : "is-unlocked is-resolution"
  return <FloatingLink {...props} name={`${props.name} (${completion}%)`} />
}

function addHeroPolygon(system, x, y) {
  return system.createPolygon(
    {
      x,
      y,
    },
    [
      [-20, -90],
      [20, -90],
      [20, 20],
      [-20, 20],
    ].map(([x, y]) => ({ x, y })),
    0,
  )
}

function drawCollisionSystem(system) {
  const canvas = document.querySelector("canvas")
  canvas.height = 1000
  canvas.width = 1000
  canvas.style.width = "100%"
  canvas.style.height = "100%"
  canvas.style.position = "absolute"
  canvas.style.zIndex = 100
  canvas.style.border = "2px solid #fff"
  canvas.style.pointerEvents = "none"

  const context = canvas.getContext("2d")
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.strokeStyle = "#FFFFFF"
  context.beginPath()
  system.draw(context)
  context.stroke()
}

function negateOverlap(system, object, setter) {
  system.getPotentials(object).forEach((collider) => {
    if (system.checkCollision(object, collider)) {
      const { overlap, overlapV } = system.response

      // Skip small pushes
      if (overlap < 5) return

      // The random element avoids updating the system too often without
      // reaching a good state
      object.x -= overlapV.x + (Math.random() - 0.5) * 40
      object.y -= overlapV.y + (Math.random() - 0.5) * 40

      setter({ x: object.x, y: object.y })
    }
  })
}
